import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef, useState } from 'react';
import useApiCall from '../system/useApiCall';
import LinkService from '../system/LinkService';
import { useSnackbar } from 'notistack';
import AvatarObjectTO from './AvatarObjectTO';
import { FormGroup, FormControlLabel, Checkbox, Box, MenuItem } from '@mui/material';
import Util from '../system/Util';

interface AvatarDataDialogProps {
  editData?: AvatarObjectTO;
  openDialog: boolean;
  setEditRow: React.Dispatch<React.SetStateAction<number | null>>;
  saveCount: number;
  setSaveCount: React.Dispatch<React.SetStateAction<number>>;
}

export default function AvatarDataDialog(props: AvatarDataDialogProps) {
  const apiCall = useApiCall();
  const { enqueueSnackbar } = useSnackbar();
  const inputFile = useRef<any>();
  const [file, setFile] = useState<File | null>(null);
  const avatarObjectDataInit: AvatarObjectTO = {
    id: -1,
    name: '',
    bodyPart: '',
    fileName: '',
    dropCategory: '',
    dropWatchTimeActive: false,
    dropChannelPointsActive: false,
    unlockWatchTime: 0,
    unlockChannelPoints: 0,
  };
  const [avatarObjectData, setAvatarObjectData] = useState<AvatarObjectTO>(
    props.editData ? props.editData : avatarObjectDataInit
  );

  React.useEffect(() => {
    if (props.editData) {
      setAvatarObjectData(props.editData);
    }
  }, [props.editData]);

  const onImportClick = () => {
    if (inputFile?.current) {
      inputFile.current.click();
    }
  };

  const onImportChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = () => {
    if (avatarObjectData.dropCategory === '') {
      enqueueSnackbar('Drop-Kategorie nicht ausgewählt.', { variant: 'error' });
      return;
    }
    if (avatarObjectData.bodyPart === '') {
      enqueueSnackbar('Körperteil nicht ausgewählt.', { variant: 'error' });
      return;
    }
    if (avatarObjectData.id < 0) {
      if (file === null) {
        enqueueSnackbar('Keine Datei ausgewählt.', { variant: 'error' });
        return;
      }
    }
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    } else {
      formData.append('file', '');
    }

    const metaDataJson = JSON.stringify(avatarObjectData);
    const metaDataBlob = new Blob([metaDataJson], { type: 'application/json' });
    formData.append('avatar', metaDataBlob);
    apiCall.post(LinkService.postAvatarObject(), formData, 'Speichern erfolgreich.').then((data) => {
      handleClose();
      props.setSaveCount(props.saveCount + 1);
    });
  };

  const handleClose = () => {
    setAvatarObjectData(avatarObjectDataInit);
    setFile(null);
    props.setEditRow(null);
  };

  const title = avatarObjectData.id === -1 ? 'Neues Objekt hinzufügen' : 'Objekt bearbeiten';

  return (
    <Dialog open={props.openDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <TextField
            margin='dense'
            disabled
            id='file'
            label='Datei'
            fullWidth
            variant='standard'
            value={file ? file.name : ''}
          />
          <Button style={{}} onClick={onImportClick}>
            Bild hochladen
          </Button>
        </Box>{' '}
        <TextField
          autoFocus
          margin='dense'
          id='name'
          label='Name'
          fullWidth
          variant='standard'
          value={avatarObjectData.name}
          onChange={(e) => setAvatarObjectData({ ...avatarObjectData, name: e.target.value })}
        />{' '}
        <TextField
          sx={{ marginTop: '1rem' }}
          select
          fullWidth
          id='bodyPart'
          value={avatarObjectData.bodyPart}
          label='Körperteil'
          onChange={(e) => setAvatarObjectData({ ...avatarObjectData, bodyPart: e.target.value })}
        >
          <MenuItem value={'BASE'}>Base</MenuItem>
          <MenuItem value={'OVERALL'}>Onesie</MenuItem>
          <MenuItem value={'HEAD'}>Kopf</MenuItem>
          <MenuItem value={'FACE'}>Gesicht</MenuItem>
          <MenuItem value={'TORSO'}>Körper</MenuItem>
          <MenuItem value={'FEET'}>Füße</MenuItem>
          <MenuItem value={'HAND_LEFT'}>linke Hand</MenuItem>
          <MenuItem value={'HAND_RIGHT'}>rechte Hand</MenuItem>
          <MenuItem value={'SIDE'}>neben Avatar</MenuItem>
          <MenuItem value={'BACKGROUND_BACK'}>Hintergrund hinten</MenuItem>
          <MenuItem value={'BACKGROUND_FRONT'}>Hintergrund vorne</MenuItem>
        </TextField>{' '}
        <TextField
          sx={{ marginTop: '1rem' }}
          select
          fullWidth
          id='dropCategory'
          value={avatarObjectData.dropCategory}
          label='Drop-Kategorie'
          onChange={(e) => setAvatarObjectData({ ...avatarObjectData, dropCategory: e.target.value })}
        >
          <MenuItem value={'GENERAL'}>Allgemein</MenuItem>
          <MenuItem value={'SIMS'}>Sims</MenuItem>
        </TextField>{' '}
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={avatarObjectData.dropWatchTimeActive}
                onChange={(e) => setAvatarObjectData({ ...avatarObjectData, dropWatchTimeActive: e.target.checked })}
              />
            }
            label='Drop Watch Time aktiviert'
          />
        </FormGroup>{' '}
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={avatarObjectData.dropChannelPointsActive}
                onChange={(e) =>
                  setAvatarObjectData({ ...avatarObjectData, dropChannelPointsActive: e.target.checked })
                }
              />
            }
            label='Drop Kanalpunkte aktiviert'
          />
        </FormGroup>{' '}
        <TextField
          margin='dense'
          id='unlockWatchTime'
          label='Freischalt-Watchtime (Minuten)'
          fullWidth
          variant='standard'
          type='number'
          value={avatarObjectData.unlockWatchTime}
          onChange={(e) =>
            setAvatarObjectData({
              ...avatarObjectData,
              unlockWatchTime: Util.isStringInt(e.target.value) ? Util.parseStringToInt(e.target.value) : -1,
            })
          }
        />{' '}
        <TextField
          margin='dense'
          id='unlockChannelPoints'
          label='Freischalt-Kanalpunkte'
          fullWidth
          variant='standard'
          type='number'
          value={avatarObjectData.unlockChannelPoints}
          onChange={(e) =>
            setAvatarObjectData({
              ...avatarObjectData,
              unlockChannelPoints: Util.isStringInt(e.target.value) ? Util.parseStringToInt(e.target.value) : -1,
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <input id='fileInput' type='file' ref={inputFile} style={{ display: 'none' }} onChange={onImportChange} />
        <Button onClick={handleCancel}>Abbrechen</Button>
        <Button onClick={handleSave}>Speichern</Button>
      </DialogActions>
    </Dialog>
  );
}
